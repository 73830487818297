<template>
  <div class="dashboard">
    <div class="row">
      <div class="col-12 col-lg-4 mb-3 pr-3 pr-lg-2">
        <div class="card h-100">
          <div class="card-body d-flex flex-column">
            <h5 class="card-title text-default-dark text-center">
              Sites
              <span
                class="badge badge-dark bg-default-dark text-default-dark px-3 py-2"
              >
                {{ sites }}
              </span>
            </h5>
            <router-link
              :to="{
                name: 'disaster.map',
              }"
              class="text-center flex-fill d-flex justify-content-center align-items-center bg-default-dark"
            >
              <img
                id="dashboard-map"
                class="card-image user-select-none"
                :src="mapUrl"
              />
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-lg-4 mb-3 pl-3 pl-lg-2 pr-3 pr-sm-2">
        <div class="card h-100">
          <div class="card-body">
            <div id="dashboard-chart-container" class="h-100 position-relative">
              <stacked-bar-chart
                id="dashboard-chart"
                v-if="chartReady"
                :chartData="chartData"
                class="dashboard-chart"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-lg-4 mb-3 pl-3 pl-sm-2">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="card-title text-default-dark text-center">
              Latest Satellite Image
            </h5>
            <div
              class="card-height clickable"
              style="height: calc(100% - 19px - 0.75rem)"
              @click="viewImage('latestSatellite', 'Latest Satellite Image')"
            >
              <img :src="metImages.latestSatellite" class="card-image" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 mb-3 pr-3 pr-sm-2">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="card-title text-default-dark text-center">
              Latest Current Radar Image
            </h5>
            <div
              class="card-height clickable"
              style="height: calc(100% - 19px - 0.75rem)"
              @click="
                viewImage('latestCurrentRadar', 'Latest Current Radar Image')
              "
            >
              <img :src="metImages.latestCurrentRadar" class="card-image" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 mb-3 pl-3 pl-sm-2">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="card-title text-default-dark text-center">
              Latest Forecast Radar Image
            </h5>
            <div
              class="card-height clickable"
              style="height: calc(100% - 19px - 0.75rem)"
              @click="
                viewImage('latestForecastRadar', 'Latest Forecast Radar Image')
              "
            >
              <img :src="metImages.latestForecastRadar" class="card-image" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <spinner :show="isLoading" />
    <media-preview
      v-if="currentMedia"
      :title="currentMediaTitle"
      :subtitle="currentMediaSubtitle"
      :media="currentMedia"
      :hasNavigation="false"
      @close="viewImage()"
    />
  </div>
</template>

<script>
import StackedBarChart from "@/components/StackedBarChart";
import MediaPreview from "@/components/MediaPreview";
import Spinner from "@/components/Spinner.vue";

export default {
  data() {
    return {
      isLoading: false,
      sites: 0,
      mapUrl: "",
      currentMedia: null,
      currentMediaTitle: null,
      currentMediaSubtitle: null,
      metImages: {
        latestSatellite:
          "https://api.met.gov.my/static/images/satelit-latest.gif",
        latestCurrentRadar:
          "https://api.met.gov.my/static/images/radar-latest.gif",
        latestForecastRadar:
          "https://api.met.gov.my/static/images/swirl-latest.gif",
      },
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Low Severity",
            backgroundColor: "#cfe2ff",
            data: [],
          },
          {
            label: "Medium Severity",
            backgroundColor: "#fecba1",
            data: [],
          },
          {
            label: "High Severity",
            backgroundColor: "#ea868f",
            data: [],
          },
        ],
      },
      chartReady: false,
      userSites: [],
    };
  },
  components: {
    StackedBarChart,
    Spinner,
    MediaPreview,
  },
  methods: {
    cardResize() {
      const mapItem = document.getElementById("dashboard-map");

      const chart = document.getElementById("dashboard-chart");

      if (mapItem && chart) {
        const mapHeight = mapItem.offsetHeight;

        chart.lastElementChild.style.height = `${mapHeight}px`;
      }
    },
    viewImage(type, title) {
      if (type) {
        this.currentMediaTitle = title;
        this.currentMediaSubtitle =
          "Image from Malaysian Meteorological Department Web Service API";
        this.currentMedia = { url: this.metImages[type], mime: "gif" };
      } else {
        this.currentMedia = null;
        this.currentMediaTitle = null;
        this.currentMediaSubtitle = null;
      }
    },
    async getSites() {
      this.isLoading = true;

      this.chartReady = false;

      let url = "sites?";

      if (this.userSites.length > 0) {
        this.userSites.forEach((site) => {
          url += `id_in=${site}&`;
        });
      }

      const [call, err] = await this.Helper.handle(this.API.get(url));

      if (!err && call.status == 200) {
        this.sites = call.data.length;

        let siteMarkers = "";

        if (this.sites > 0) {
          let markers = [];

          call.data.forEach((site) => {
            markers.push(`pin-l+0061ff(${site.lng},${site.lat})`);

            if (
              !this.chartData.labels.find(
                (label) => label.toLowerCase() == site.type
              )
            ) {
              this.chartData.labels.push(site.type.toUpperCase());
            }
          });

          this.chartData.datasets = this.chartData.datasets.map((dataset) => {
            this.chartData.labels.forEach(() => {
              dataset.data.push(0);
            });

            return dataset;
          });

          call.data.forEach((site) => {
            const dataset = this.chartData.datasets.find(
              (ds) => ds.label == site.severity.concat(" Severity")
            );

            if (dataset) {
              let dataIndex = this.chartData.labels.findIndex(
                (label) => label.toLowerCase() == site.type.toLowerCase()
              );

              dataset.data[dataIndex] += 1;
            }
          });

          this.chartReady = true;

          this.$nextTick(() => {
            this.cardResize();
          });

          siteMarkers = "/" + markers.join(",");
        }

        this.mapUrl = `https://api.mapbox.com/styles/v1/mapbox/${this.Helper.mapStyle(
          false,
          true
        )}/static${siteMarkers}/auto/500x375@2x?padding=0, 50&access_token=${
          process.env.VUE_APP_MAPBOX_KEY
        }`;
      }

      this.isLoading = false;
    },
  },
  mounted() {
    if (localStorage.getItem("user")) {
      const _user = JSON.parse(localStorage.getItem("user"));

      if (_user && _user.sites) {
        this.userSites = _user.sites.map((site) => site._id);
      }
    }

    this.getSites();

    window.addEventListener("resize", this.cardResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.cardResize);
  },
};
</script>
