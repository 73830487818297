<script>
import { Bar, mixins } from "vue-chartjs";
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  mounted() {
    this.addPlugin(ChartJsPluginDataLabels);

    this.renderChart(this.chartData, {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          color: "#222e36",
          labels: {
            title: {
              font: {
                weight: "bold",
                size: 13,
              },
            },
          },
          formatter: function (value, context) {
            return value > 0 ? value : "";
          },
        },
      },
      legend: {
        labels: {
          boxWidth: 10,
          usePointStyle: true,
          fontColor: "#88857a",
        },
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
      },
      scales: {
        xAxes: [
          {
            stacked: true,
            gridLines: {
              display: true,
              color: "#6c757d",
              zeroLineColor: "#6c757d",
              drawTicks: false,
            },
            ticks: {
              beginAtZero: true,
              fontColor: "#88857a",
              fontStyle: "bold",
              padding: 10,
            },
          },
        ],
        yAxes: [
          {
            stacked: true,
            gridLines: {
              beginAtZero: true,
              display: true,
              color: "#6c757d",
              zeroLineColor: "#6c757d",
            },
            ticks: {
              beginAtZero: true,
              stepSize: 1,
              fontColor: "#88857a",
              fontStyle: "bold",
              padding: 10,
            },
          },
        ],
      },
    });
  },
};
</script>
